<template>
  <multiselect
    class="with-border health-plan-select"
    ref="health_plan_id"
    v-model="clinicHealthPlan"
    :options="clinicHealthPlans"
    track-by="id"
    label="fantasy_name"
    :multiple="multiple"
    :showLabels="false"
    :allowEmpty="false"
    :placeholder="placeholder || 'Filtrar por Convênio...'"
    @select="onSelect"
  >
    <template #caret>
      <div class="chevron" :class="{'moved-chevron': visibleIcon}">
        <ChevronDown />
      </div>
      <div class="close clear" @click.prevent="clear" v-if="visibleIcon">
        <Close />
      </div>
    </template>
    
    <template v-if="multiple" #option="props">
      <div class="d-flex">
        <Check
          class="mr-1"
          type="checkbox" 
          :value="isSelected(props.option)"
          @input="toggleOption(props.option)"
        />
        <label>{{ props.option.fantasy_name }}</label>
      </div>
    </template>
    
    <template v-else #singleLabel="props">
      {{ props.option.fantasy_name }}
    </template>
    
    <template v-if="multiple" #selection="props">
      <span class="ml-3"
        v-b-tooltip.hover :title="props.values.map(option => option.fantasy_name).join(', ')"
      >
        {{ getLabel(props.values) }}
      </span>
    </template>
    <template #noOptions>Nenhuma opção</template>
    <template #noResult>Nenhum resultado</template>
  </multiselect>
</template>

<script>
export default {
  name: "HealthPlanSelect",
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    Check: () => import('@/components/General/Check')
  },
  props: {
    value: [String, Array],
    required: Boolean,
    validated: Boolean,
    placeholder: String,
    clinicId: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      clinicHealthPlan: this.multiple ? [] : null,
      clinicHealthPlans: [],
      temporaryValue: [], 
    }
  },
  mounted() {
    this.getClinicHealthPlans().then(() => {
      if (this.multiple) {
        if(this.temporaryValue) this.clinicHealthPlan = this.clinicHealthPlans.filter(hp => this.temporaryValue.includes(hp.id))
      } else {
        this.clinicHealthPlan = this.clinicHealthPlans.find(hp => hp.id === this.value)
      }
    });
  },
  computed: {
    visibleIcon() {
      return this.multiple ? this.clinicHealthPlan.length > 0 : this.clinicHealthPlan
    },
  },
  methods: {
    getClinicHealthPlans() {
        return this.api.getAllClinicHealthPlans(this.clinicId).then(res => {
            this.clinicHealthPlans = res.data.map(clinicHealthPlan => {
                return {
                    id: clinicHealthPlan.id,
                    fantasy_name: clinicHealthPlan.health_plan.fantasy_name,
                    clinic_health_plan: clinicHealthPlan
                };
            });
        }).catch(error => {
            console.error('Erro ao carregar os dados', error);
        });
    },
    clear() {
      this.clinicHealthPlan = this.multiple ? [] : null
    },
    onSelect(option) {
      if (this.multiple) {
        const value = this.clinicHealthPlan.map(hp => hp.id)
        this.$emit('select', value)
        this.$emit('input', value)
        this.$emit('selectClinicHealthPlans', this.clinicHealthPlan.map(hp => hp.clinic_health_plan))
      } else {
        const value = this.clinicHealthPlan?.id || null
        this.$emit('select', value)
        this.$emit('input', value)
        this.$emit('selectClinicHealthPlan', option.clinic_health_plan)
      }
    },
    isSelected(option) {
      return this.clinicHealthPlan.some(hp => hp.id === option.id);
    },
    toggleOption(option) {
      if (this.isSelected(option)) {
        this.clinicHealthPlan = this.clinicHealthPlan.filter(hp => hp.id !== option.id);
      } else {
        this.clinicHealthPlan.push(option);
      }
    },
    getLabel(values){
      const label = values.length ? values.map(option => option.fantasy_name).join(', ') : ''
      return label.length > 50 ? label.substring(0, 50) + '...' : label
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue) {
        if (this.multiple) {
          this.temporaryValue = newValue
        } else {
          this.clinicHealthPlan = this.clinicHealthPlans.find(hp => hp.id === newValue)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.health-plan-select {
  .clear {
    width: 18px;
    height: 28px;
    padding: 0;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    position: absolute;
    transition: all 500ms;
    z-index: 100;
  }

  .moved-chevron {
    right: 32px;
  }
}
</style>